import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { Link, RouteComponentProps } from "react-router-dom";
import clsx from "clsx";
import QuidTitle from "components/atoms/QuidTitle";
import TransactionDetail from "components/organisms/accounts/TransactionDetail";
import { handleFailure } from "resHandlers";
import { firstCapitalLetter, formatDate } from "utils";
import AccountStatusBadge, {
  AccountBadgeStatuses,
} from "components/organisms/accounts/AccountStatusBadge";
import { MoneyFormatter } from "../shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { fetchBalanceByBalanceId } from "api/accounts";
import { BalanceResponse } from "entities/accounts/Balance";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { downloadStatementsPDFByDateRange } from "services/accounts";
import DownloadIcon from "components/atoms/icons/DownloadIcon";
import { fetchRewardsInformationsByWalletId } from "api/rewadrs";
import { RewardInfoResponse } from "entities/rewards/RewardEntity";

const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
  },
  status: {
    display: "flex",
    fontSize: 14,
    flexDirection: "row",
    paddingRight: 24,
  },
  infoContainer: {
    width: "100%",
    height: 60,
    display: "flex",
    fontSize: 18,
    flexDirection: "row",
    background: "#F3F3F3",
    alignItems: "center",
    color: "#37383C",
    justifyContent: "space-between",
    "& a": {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
      paddingLeft: 24,
    },
    "& span": {
      paddingLeft: 24,
    },
  },
  detailContainer: {
    background: "#FAFAFA",
    width: "100%",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  flex1: {
    flex: 1,
  },
  flexContainer: {
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },
  mt16: {
    marginTop: 16,
  },
  pl24: {
    paddingLeft: 24,
  },
  pr8: {
    paddingRight: 8,
  },
  pl8: {
    paddingLeft: 8,
  },
  pr24: {
    paddingRight: 24,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  statementsTitle: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginTop: 64,
    marginBottom: 32,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  red: {
    background: "#FB4C4C",
  },
  green: {
    background: "#94E60F",
  },
  fullHeight: {
    height: "100%",
  },
  actionBtn: {
    height: 52,
    "&.MuiButton-root": {
      width: "90%",
    },
  },
  taskLink: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  pdfBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
}));

export interface AccountDetailsToPrint {
  created_at: string;
  account_holder: string;
  type: string;
  currency: string;
  iban: string;
  wallet_id: string;
}

export interface RewardDetailsToPrint {
  stream_amount: string;
  stream_per_second: string;
}

export interface BalanceInfo {
  total_balance: string;
  balanceId: number;
  available_balance: string;
}

const AccountBalanceDetail: React.FC<
  RouteComponentProps<{ accountId: string; id: string }>
> = ({ match }) => {
  const { t } = useTranslation("account");

  const classes = useStyles();
  const balanceId = parseInt(match.params.id, 10);

  const [rewardInformations, setRewardInformations] = useState(
    {} as RewardInfoResponse
  );
  const [details, setDetails] = useState({} as BalanceResponse);
  const [filter, setFilter] = useState({
    enddate: "",
    startdate: "",
  });

  const accountDetailsToPrint: AccountDetailsToPrint = {
    created_at: formatDate(details?.created_at) || "n/a",
    account_holder: details?.account_holder || "n/a",
    type: details?.type || "n/a",
    currency: details?.currency || "n/a",
    iban: details?.iban || "n/a",
    wallet_id: details?.wallet_id || "n/a",
  };

  const RewardToPrint: RewardDetailsToPrint = {
    stream_per_second:
      `${`${details?.currency}/sec ${rewardInformations?.stream_per_second}`}` ||
      "n/a",
    stream_amount:
      `${`${details?.currency} ${rewardInformations?.stream_amount}`}` || "n/a",
  };

  const balancesToPrint: BalanceInfo = {
    balanceId,
    total_balance:
      MoneyFormatter(
        details?.current_amount,
        details?.currency as DineroFactory.Currency
      ) || "n/a",
    available_balance: MoneyFormatter(
      details?.available_amount,
      details?.currency as DineroFactory.Currency
    ),
  };

  const fetchBalanceDetails = useCallback(async () => {
    try {
      if (balanceId) {
        const balanceRes = await fetchBalanceByBalanceId({
          balance_id: balanceId,
        });
        setDetails(balanceRes);

        if (process.env.ENABLE_REWARDS) {
          if (balanceRes) {
            const rewardsInfo = await fetchRewardsInformationsByWalletId({
              wallet_id: balanceRes?.wallet_id,
            });
            setRewardInformations(rewardsInfo);
          }
        }
      }
    } catch (err: any) {
      handleFailure(err);
    }
  }, [balanceId]);

  useEffect(() => {
    fetchBalanceDetails();
  }, []);

  const downloadPdfByDateRange = useCsvDownloadHandler({
    download: () =>
      downloadStatementsPDFByDateRange({
        fileName: "statements ",
        balanceId,
        ...(filter?.startdate && { startdate: filter.startdate }),
        ...(filter?.enddate && { enddate: filter.enddate }),
      }),
  });

  const margins = clsx(classes.pl24, classes.pr24, classes.mt32, classes.mb32);

  return (
    <MainTemplate>
      <div className={classes.accountsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("balance__details__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.infoContainer}>
          <>
            <Link to={`/customers/${details?.account_id}`}>
              {details?.account_holder}
            </Link>
          </>
          <div className={classes.status}>
            <AccountStatusBadge
              status={details?.status as AccountBadgeStatuses}
            />
            <span>{firstCapitalLetter(details?.status)}</span>
          </div>
        </div>
        <div className={classes.detailContainer}>
          <Grid container className={margins} justify={"center"}>
            <Grid item xs={4}>
              <DetailsBlock
                label="account__details__menu__title"
                toPrint={accountDetailsToPrint}
              />
            </Grid>
            <Grid item xs={4}>
              <DetailsBlock
                label="account__details__balances"
                toPrint={balancesToPrint}
              />
            </Grid>
            {process.env.ENABLE_REWARDS && (
              <Grid item xs={4}>
                <DetailsBlock
                  label="account__details__balances"
                  toPrint={RewardToPrint}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <div>
          {/* {details.reward_details.map(()=>{
            return()
          })} */}
        </div>
        <Grid container direction="row" spacing={1} className={classes.mt32}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.statementsTitle}>
                {t("account__statements")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("start_date")}
              variant="outlined"
              type="date"
              fullWidth
              value={filter.startdate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({ ...state, startdate: e.target.value }))
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("end_date")}
              variant="outlined"
              type="date"
              fullWidth
              value={filter.enddate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({ ...state, enddate: e.target.value }))
              }
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={3}>
            <Button
              onClick={() => downloadPdfByDateRange.onCsvDownload()}
              variant="outlined"
              className={classes.pdfBtn}
              disabled={!filter?.startdate || !filter?.enddate}
            >
              <DownloadIcon
                color={
                  !filter?.startdate || !filter?.enddate ? "#91A1AD" : "#42206D"
                }
              />
            </Button>
          </Grid>
        </Grid>
        <TransactionDetail balanceId={balanceId} />
      </div>
    </MainTemplate>
  );
};

export default AccountBalanceDetail;
