import { format } from "date-fns";
import fileDownload from "js-file-download";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import {
  downloadCardListDocument,
  downloadCardProgramsDocument,
  downloadCardTransactionsDocument,
} from "api/cards";
import { CardsListParams } from "entities/cards/CardEntity";

export async function downloaCardProgramsCsv(): Promise<FilePayload> {
  try {
    const res = await downloadCardProgramsDocument();
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `card-programs-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloaCardListCsv(): Promise<FilePayload> {
  try {
    const res = await downloadCardListDocument();
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `card-list-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloaCardTransactionsCsv(
  params: CardsListParams
): Promise<FilePayload> {
  try {
    const res = await downloadCardTransactionsDocument(params);
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `card-transactions-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}
