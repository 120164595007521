import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import { Pagination } from "entities/accounts/Pagination";
import { fetchGiftCardsList } from "api/cards";
import { RouteComponentProps } from "react-router-dom";
import { GiftCards as GiftCardsEntity } from "entities/cards/CardEntity";
import StatusBadge from "components/atoms/StatusBadge";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { formatDate } from "utils";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";

const useStyles = makeStyles((theme) => ({
  offerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
}));

const GiftCards: React.FC<RouteComponentProps<{ accountId: string }>> = () => {
  const classes = useStyles();
  const { t } = useTranslation("cards");
  const dispatch = useDispatch();
  const [cards, setCards] = useState([] as GiftCardsEntity[]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      field: "party_id",
      flex: 1,
      headerName: t("listTable__header_name__party_id"),
    },
    {
      field: "number",
      flex: 1,
      headerName: t("listTable__header_name__number"),
      renderCell: (params) => params?.row?.card?.number,
    },
    {
      field: "provider",
      flex: 1,
      headerName: t("listTable__header_name__provider"),
      renderCell: (params) => params?.row?.card?.provider,
    },
    {
      field: "balance",
      flex: 1,
      headerName: t("listTable__header_name__balance"),
      renderCell: (params) => (
        <>
          {MoneyFormatter(
            params?.row?.card?.balance,
            params?.row?.card?.currency as DineroFactory.Currency
          ) || "n/a"}
        </>
      ),
    },
    {
      field: "currency",
      flex: 1,
      headerName: t("listTable__header_name__currency"),
      renderCell: (params) => params?.row?.card?.currency,
    },
    {
      field: "holder_name",
      flex: 1,
      headerName: t("listTable__header_name__holder_name"),
      renderCell: (params) => params?.row?.card?.holder_name,
    },
    {
      field: "expiry",
      flex: 1,
      headerName: t("listTable__header_name__expiration_date"),
      renderCell: (params) => (
        <>{formatDate(params.row?.card?.expiry, "dd/MM/yyyy")}</>
      ),
    },
    {
      field: "status",
      flex: 1,
      headerName: t("listTable__header_name__status"),
      renderCell: (params) => (
        <StatusBadge status={params?.row?.card?.status} />
      ),
    },
    // {
    //   field: "",
    //   headerName: t("listTable__header_name__details"),
    //   width: 100,
    //   renderCell: (params) => (
    //     <DetailButton to={`/cards/gift-cards/${params?.row?.id}/details`} />
    //   ),
    // },
  ];

  useEffect(() => {
    const getCards = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchGiftCardsList({
          page,
          size: TABLE_PAGE_SIZE,
        });

        setPagination(res?.pagination);
        setCards(res.gift_cards);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCards();
  }, [page]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  return (
    <MainTemplate>
      <div className={classes.offerContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("gift_cards__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <QuidDataGrid
          disableSelectionOnClick
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={cards}
          rowCount={pagination?.total_entries}
          pageSize={10}
        />
      </div>
    </MainTemplate>
  );
};

export default GiftCards;
