import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { Pagination } from "entities/accounts/Pagination";
import { fetchCardProviders } from "api/cards";
import { Providers } from "entities/cards/CardEntity";
import { TABLE_PAGE_SIZE } from "shared/constants";

const useStyles = makeStyles(() => ({
  cardProvidersContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
}));

const CardProviders: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("cards");
  const dispatch = useDispatch();
  const [cards, setProviders] = useState([] as Providers[]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      field: "provider",
      flex: 1,
      headerName: t("listTable__header_name__provider"),
    },
    {
      field: "currencies",
      flex: 1,
      headerName: t("listTable__header_name__currencies"),
      renderCell: (params) => params?.row?.currencies?.join(", "),
    },
    {
      field: "regions",
      flex: 1,
      headerName: t("listTable__header_name__regions"),
      renderCell: (params) => params?.row?.regions?.join(", "),
    },
    {
      field: "types",
      flex: 1,
      headerName: t("listTable__header_name__types"),
      renderCell: (params) => params?.row?.types?.join(", "),
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      width: 100,
      renderCell: (params) => (
        <DetailButton to={`/cards/providers/${params?.row?.id}/details`} />
      ),
    },
  ];

  useEffect(() => {
    const getCardProviders = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCardProviders({
          page,
          size: TABLE_PAGE_SIZE,
        });

        setPagination(res?.pagination);
        setProviders(res.providers);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCardProviders();
  }, [page]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  return (
    <MainTemplate>
      <div className={classes.cardProvidersContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("card_providers__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <QuidDataGrid
          disableSelectionOnClick
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={cards}
          rowCount={pagination?.total_entries}
          pageSize={10}
        />
      </div>
    </MainTemplate>
  );
};

export default CardProviders;
