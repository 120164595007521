import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { Pagination } from "entities/accounts/Pagination";
import { fetchCardProgramsList } from "api/cards";
import { CardProgram } from "entities/cards/CardEntity";
import StatusBadge from "components/atoms/StatusBadge";
import { TABLE_PAGE_SIZE } from "shared/constants";
import CsvIcon from "components/atoms/icons/CsvIcon";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { downloaCardProgramsCsv } from "services/cards";
import Loader from "components/atoms/icons/Loader";

const useStyles = makeStyles(() => ({
  cardProgramsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  buttonCsvContainer: {
    marginLeft: "auto",
    width: 80,
  },
}));

const CardPrograms: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation("cards");

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({} as Pagination);
  const [cardProgramsList, setCardProgramsList] = useState([] as CardProgram[]);

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      field: "name",
      flex: 1,
      headerName: t("listTable__header_name__name"),
    },
    {
      field: "provider_type",
      flex: 1,
      headerName: t("listTable__header_name__provider"),
    },
    {
      field: "currency",
      flex: 1,
      headerName: t("listTable__header_name__currency"),
    },
    {
      field: "type",
      flex: 1,
      headerName: t("listTable__header_name__type"),
    },
    {
      field: "status",
      flex: 1,
      headerName: t("listTable__header_name__status"),
      renderCell: (params) => <StatusBadge status={params?.row?.status} />,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      width: 100,
      renderCell: (params) => (
        <DetailButton to={`/cards/card-programs/${params?.row?.id}/details`} />
      ),
    },
  ];

  useEffect(() => {
    const getCardPrograms = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCardProgramsList({
          page,
          size: TABLE_PAGE_SIZE,
        });

        setPagination(res?.pagination);
        setCardProgramsList(res.programs);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCardPrograms();
  }, [page]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () => downloaCardProgramsCsv(),
  });

  return (
    <>
      {documentLoading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <div className={classes.cardProgramsContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("card_programs__page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <div className={classes.buttonCsvContainer}>
            <Button
              variant="outlined"
              onClick={() => onCsvDownload()}
              className={classes.csvBtn}
            >
              <CsvIcon />
            </Button>
          </div>
          <QuidDataGrid
            disableSelectionOnClick
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={cardProgramsList}
            rowCount={pagination?.total_entries}
            pageSize={10}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default CardPrograms;
