import React, { useState, useEffect } from "react";
import { Button, Grid, makeStyles, MenuItem } from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import { TransferOnBehalfParams } from "@portit/core/entities/Crypto";
import { postTransferOnBehalf } from "@portit/core/api/Investments";
import { fetchBeneficiaries, fetchUserAccount } from "api/accounts";
import {
  AccountResponse,
  BeneficiariesResponse,
} from "entities/accounts/Account";
import { handleFailure } from "resHandlers";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { Balance } from "entities/accounts/Balance";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    maxWidth: "100%",
  },
  flex1: {
    flex: 1,
  },
  mt56: {
    marginTop: 56,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "5px",
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  checkboxWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    textAlign: "center",
    alignItems: "center",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
    gap: "1rem",
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
  accountDetailsWrapper: {
    background: "#fff",
    padding: 15,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
}));

const TransferOnBehalf: React.FC = () => {
  const { t } = useTranslation("payments");
  const classes = useStyles();
  const dispatch = useDispatch();

  const [fromAccounts, setFromAccounts] = useState<AccountResponse[]>([]);
  const [fromAccount, setFromAccount] = useState<AccountResponse>();

  const [beneficiary, setBeneficiary] = useState<BeneficiariesResponse>();
  const [beneficiaries, setBeneficiaries] = useState<BeneficiariesResponse[]>(
    []
  );

  const [balance, setBalance] = useState<Balance>();

  const paymentsType = ["regular"];

  const defaultValues = {
    amount: 0,
    balance_id: "",
    account_id: "",
    beneficiary_id: 0,
    currency: "",
    payment_type: "",
    reason: "",
    reference: "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onResetForm = () => {
    reset(defaultValues);
  };

  const onFormSubmit = async (values: TransferOnBehalfParams) => {
    try {
      if (fromAccount?.owner_id) {
        await postTransferOnBehalf(fromAccount?.owner_id, {
          amount: Number(values.amount),
          balance_id: balance?.balance_id || 0,
          account_id: fromAccount?.account_id || 0,
          beneficiary_id: beneficiary?.beneficiary_id || 0,
          currency: balance?.currency || "EUR",
          payment_type: values.payment_type,
          reason: values.reason,
          reference: values.reference,
        });
      }
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("transfer__success__message"),
          type: "success",
        })
      );
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const { accounts } = await fetchUserAccount({
          size: TABLE_PAGE_SIZE,
        });
        setFromAccounts(accounts);
      } catch (err: any) {
        handleFailure(err);
      }
    };

    void getAllUsers();
  }, []);

  useEffect(() => {
    if (fromAccount?.account_id) {
      const fetchBeneficiariesFromAccountId = async () => {
        try {
          const res = await fetchBeneficiaries({
            accountId: fromAccount.account_id.toString(),
          });

          setBeneficiaries(res?.beneficiaries);
        } catch (err: any) {
          handleFailure(err);
        }
      };

      void fetchBeneficiariesFromAccountId();
    }
  }, [fromAccount?.account_id]);

  return (
    <MainTemplate>
      <QuidTitle>{t("transfer__page__title")}</QuidTitle>
      <div className={classes.container}>
        <FormProvider {...methods}>
          <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container direction={"column"} spacing={1}>
              <Grid item>
                <QuidTextField
                  name="amount"
                  type="number"
                  label={t("amount_label")}
                  rules={{
                    required: t("amount__required") as string,
                  }}
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  defaultValues={defaultValues}
                />

                <Grid item>
                  <Grid item>
                    <QuidTextField
                      name="from_account"
                      label={t("select__account")}
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      rules={undefined}
                      defaultValues={defaultValues}
                    >
                      <MenuItem value="">
                        <em>{t("select__account")}</em>
                      </MenuItem>
                      {fromAccounts?.map(
                        (account: AccountResponse, index: number) => (
                          <MenuItem
                            key={index}
                            value={account.account_id}
                            onClick={() => setFromAccount(account)}
                          >
                            {account.account_name} - {account.account_id} <br />
                            <small>{account.account_holder}</small>
                          </MenuItem>
                        )
                      )}
                    </QuidTextField>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid item>
                    <QuidTextField
                      name="balance"
                      label={t("select___balance__label")}
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      rules={undefined}
                      defaultValues={defaultValues}
                    >
                      <MenuItem value="">
                        <em>{t("select__balance")}</em>
                      </MenuItem>
                      {fromAccount?.balances?.map(
                        (balance: Balance, index: number) => (
                          <MenuItem
                            key={index}
                            value={balance.wallet_id}
                            onClick={() => setBalance(balance)}
                          >
                            {balance.wallet_id} <br />
                            <small>
                              {balance.available_amount}-{balance.currency}
                            </small>
                          </MenuItem>
                        )
                      )}
                    </QuidTextField>
                  </Grid>
                </Grid>
                <Grid item>
                  <QuidTextField
                    name="to__account"
                    label={t("select__beneficiary")}
                    textFieldProps={{
                      select: true,
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    rules={undefined}
                    defaultValues={defaultValues}
                  >
                    <MenuItem value="">
                      <em>{t("select__beneficiary")}</em>
                    </MenuItem>
                    {beneficiaries?.map(
                      (beneficiary: BeneficiariesResponse, index: number) => (
                        <MenuItem
                          key={index}
                          value={beneficiary.beneficiary_id}
                          onClick={() => setBeneficiary(beneficiary)}
                        >
                          {beneficiary.beneficiary_id} - {beneficiary.name}
                        </MenuItem>
                      )
                    )}
                  </QuidTextField>
                </Grid>
                <Grid item>
                  <QuidTextField
                    name="payment_type"
                    label={t("payment__type__label")}
                    rules={{
                      required: t("payment_type__required") as string,
                    }}
                    defaultValues={defaultValues}
                    textFieldProps={{
                      select: true,
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                  >
                    <MenuItem value="">{t("select__payment__type")}</MenuItem>
                    {paymentsType.map((paymentType) => (
                      <MenuItem key={paymentType} value={paymentType}>
                        {paymentType}
                      </MenuItem>
                    ))}
                  </QuidTextField>
                </Grid>
                <Grid item>
                  <QuidTextField
                    name="reason"
                    label={t("reason__label")}
                    rules={{
                      required: t("reason__required") as string,
                    }}
                    defaultValues={defaultValues}
                    textFieldProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item>
                  <QuidTextField
                    name="reference"
                    label={t("reference__label")}
                    rules={{
                      required: t("reference__required") as string,
                    }}
                    defaultValues={defaultValues}
                    textFieldProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonsWrapper}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onResetForm}
                className="actions actions-approve"
              >
                {t("transfer__action__reset")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="actions actions-approve"
              >
                {t("transfer__action__submit")}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </MainTemplate>
  );
};

export default TransferOnBehalf;
