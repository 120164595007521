import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { Pagination } from "entities/accounts/Pagination";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { fetchCardsList } from "api/cards";
import { Card } from "entities/cards/CardEntity";
import StatusBadge from "components/atoms/StatusBadge";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { formatDate } from "utils";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { downloaCardListCsv } from "services/cards";
import CsvIcon from "components/atoms/icons/CsvIcon";
import Loader from "components/atoms/icons/Loader";

const useStyles = makeStyles((theme) => ({
  cardListContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
}));

const statuses = ["Active", "Suspended", "Cancelled", "Stolen"];

const CardList: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("cards");
  const dispatch = useDispatch();
  const [cards, setCards] = useState([] as Card[]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);
  const [filter, setFilter] = useState({
    status: "",
    partyId: "",
    provider: "",
  });

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      field: "party_id",
      flex: 1,
      headerName: t("listTable__header_name__party_id"),
    },
    {
      field: "number",
      flex: 1,
      headerName: t("listTable__header_name__number"),
    },
    {
      field: "provider",
      flex: 1,
      headerName: t("listTable__header_name__provider"),
    },
    {
      field: "balance",
      flex: 1,
      headerName: t("listTable__header_name__balance"),
      renderCell: (params) => (
        <>
          {MoneyFormatter(
            params?.row?.balance,
            params?.row?.currency as DineroFactory.Currency
          ) || "n/a"}
        </>
      ),
    },
    {
      field: "currency",
      flex: 1,
      headerName: t("listTable__header_name__currency"),
    },
    {
      field: "holder_name",
      flex: 1,
      headerName: t("listTable__header_name__holder_name"),
    },
    {
      field: "expiry",
      flex: 1,
      headerName: t("listTable__header_name__expiration_date"),
      renderCell: (params) => (
        <>{formatDate(params.row?.expiry, "dd/MM/yyyy")}</>
      ),
    },
    {
      field: "status",
      flex: 1,
      headerName: t("listTable__header_name__status"),
      renderCell: (params) => <StatusBadge status={params?.row?.status} />,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      width: 100,
      renderCell: (params) => (
        <DetailButton to={`/cards/card-list/${params?.row?.id}/details`} />
      ),
    },
  ];

  useEffect(() => {
    const getCards = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCardsList({
          ...(filter?.status && { status: filter?.status }),
          ...(filter?.provider && { provider: filter?.provider }),
          ...(filter?.partyId && { partyId: Number(filter?.partyId) }),
          page,
          size: TABLE_PAGE_SIZE,
        });
        setPagination(res?.pagination);
        setCards(res.cards);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCards();
  }, [page, filter.provider, filter.status, filter.partyId]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () => downloaCardListCsv(),
  });

  return (
    <>
      {documentLoading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <div className={classes.cardListContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("card_list_page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <div className={classes.filterContainer}>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__provider")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFilter((state) => ({
                      ...state,
                      provider: e.target.value,
                    }));
                  }}
                  value={filter.provider}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon color="#fff" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__partyId")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFilter((state) => ({
                      ...state,
                      partyId: e.target.value,
                    }));
                  }}
                  value={filter.partyId}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <SearchIcon color="#fff" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("filter__status")}
                  variant="outlined"
                  select
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter((state) => ({
                      ...state,
                      status: e.target.value,
                    }))
                  }
                  fullWidth
                  value={filter.status}
                >
                  <MenuItem value="">
                    <em>{t("filter__status")}</em>
                  </MenuItem>
                  {statuses?.map((status: any, index: number) => (
                    <MenuItem key={`${status}-${index}`} value={status}>
                      {`${t(status)}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  onClick={() => onCsvDownload()}
                  className={classes.csvBtn}
                >
                  <CsvIcon />
                </Button>
              </Grid>
            </Grid>
          </div>
          <QuidDataGrid
            disableSelectionOnClick
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={cards}
            rowCount={pagination?.total_entries}
            pageSize={10}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default CardList;
