import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import { fetchCardProvidersById } from "api/cards";
import { Providers } from "entities/cards/CardEntity";
import { RouteComponentProps } from "react-router";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  cardProvidersContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  detailContainer: {
    background: "#FAFAFA",
    width: "100%",
  },
  pl24: {
    paddingLeft: 24,
  },
  pr24: {
    paddingRight: 24,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
}));

const CardProvidersDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("cards");

  const classes = useStyles();

  const dispatch = useDispatch();

  const id = parseInt(match.params.id, 10);

  const [provider, setProvider] = useState({} as Providers);

  const margins = clsx(classes.pl24, classes.pr24, classes.mt32, classes.mb32);

  const cardDetailsToPrint: Providers = {
    id: provider?.id || "n/a",
    provider: provider?.provider,
    regions: Array.isArray(provider?.regions)
      ? provider?.regions?.join(", ")
      : "n/a",
    currencies: Array.isArray(provider?.currencies)
      ? provider?.currencies?.join(", ")
      : "n/a",
    types: Array.isArray(provider?.types) ? provider?.types?.join(", ") : "n/a",
  };

  useEffect(() => {
    const getCardProvidersById = async (): Promise<void> => {
      try {
        if (id) {
          const res = await fetchCardProvidersById({
            id,
          });

          setProvider(res);
        }
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };

    void getCardProvidersById();
  }, [id]);

  return (
    <MainTemplate>
      <div className={classes.cardProvidersContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("card_providers_details__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.detailContainer}>
          <Grid container className={margins} justify={"center"}>
            <Grid item xs={6}>
              <DetailsBlock
                toPrint={cardDetailsToPrint}
                label={t("card__details__title")}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </MainTemplate>
  );
};

export default CardProvidersDetails;
