import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { handleFailure } from "resHandlers";
import { ServerFailure } from "features/core/Failure";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useHistory } from "react-router";
import { fetchUserAccount } from "api/accounts";
import { Balance } from "entities/accounts/Balance";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { AccountResponse } from "entities/accounts/Account";
import { fetchOpportunities } from "api/opportunities";
import { Opportunity } from "entities/opportunities/opportunityEntity";
import { createInvestemnt } from "api/investments";

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: "#FAFAFA" },
  containerInner: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  column4: { width: "calc(100% / 3 - 30px)", padding: "0px 15px" },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  containerInnerButtons: {
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 60,
  },
  checkText: {
    textAlign: "center",
    paddingTop: 13,
    fontFamily: "Nunito",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 18,
  },
  accountDetailsWrapper: {
    background: "#fff",
    padding: 15,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
}));

interface FormValues {
  currency: string;
  amount: number;
  opportunityId: number;
  investmentType: string;
  noToken?: number;
  tokenPrice?: string;
  amountInvestedForStreamable?: string;
  agreedTandC: string;
  investmentOption?: {};
  streamableType?: string;
  walletId: string;
  partyId?: number;
}

const PaymentsInternalTransfer: React.FC = () => {
  const { t } = useTranslation("payments");

  const [fromAccounts, setFromAccounts] = useState<AccountResponse[]>([]);
  const [fromAccount, setFromAccount] = useState<AccountResponse>();
  const [searchFrom, setSearchFrom] = useState("");

  const [accountSelected, setAccountSelected] = useState(false);

  const [balance, setBalance] = useState({} as Balance | undefined);
  const [streamableType, setStreamableType] = useState("false");

  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [opportunity, setOpportunity] = useState<Opportunity>();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultValues = {
    streamableType: false,
    from_account_type: "",
    from_account_status: "enabled",
    opportunityName: "",
    opportunityStatus: "",
    from_Search: "",
    to_search: "",
    amount: 0,
  };

  const fromAccountDetails = {
    id: fromAccount?.account_id || "n/a",
    account_name: fromAccount?.account_name || "n/a",
    account_holder: fromAccount?.account_holder || "n/a",
    quid_reference: fromAccount?.quid_reference || "n/a",
    account_type: fromAccount?.account_type || "n/a",
    street: fromAccount?.street || "n/a",
    city: fromAccount?.city || "n/a",
    postal_code: fromAccount?.postal_code || "n/a",
    country: fromAccount?.country || "n/a",
    state_or_province: fromAccount?.state_or_province || "n/a",
    account_status: fromAccount?.status || "n/a",
  };

  const opportunityDetails = {
    id: opportunity?.id || "n/a",
    name: opportunity?.name || "n/a",
    currency: opportunity?.currency || "n/a",
    network: opportunity?.network || "n/a",
    horizon: opportunity?.horizon || "n/a",
    stage: opportunity?.stage || "n/a",
    sector: opportunity?.sector || "n/a",
    tokenPrice: opportunity?.tokenPrice || "n/a",
    noToken: opportunity?.noToken || "n/a",
    valuation: opportunity?.valuation || "n/a",
    status: opportunity?.status || "n/a",
    streamableWalletAddress: opportunity?.streamableWalletAddress || "n/a",
    streamableCurrency: opportunity?.streamableCurrency || "n/a",
    referralPercentage: opportunity?.referralPercentage || "n/a",
  };

  const transactionDetails = {
    current_amount:
      `${MoneyFormatter(
        balance?.current_amount || 0,
        balance?.currency as DineroFactory.Currency
      )}` || "n/a",
    available_amount:
      `${MoneyFormatter(
        balance?.available_amount || 0,
        balance?.currency as DineroFactory.Currency
      )}` || "n/a",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, watch, reset } = methods;

  const fromAccountType = watch("from_account_type");
  const fromAccountStatus = watch("from_account_status");

  const investmentTypeOptions = ["Buy", "Sell", "Withdrawn"];
  const agreedTandCOptions = [
    { value: "true", label: "true" },
    { value: "false", label: "false" },
  ];

  const onResetForm = () => {
    reset(defaultValues);
    setFromAccount(undefined);
    setOpportunity(undefined);
    setSearchFrom("");
    setFromAccounts([]);
    setAccountSelected(false);
    setBalance(undefined);
  };

  useEffect(() => {
    const balance = fromAccount?.balances?.filter(
      (balance: Balance) => balance?.currency === opportunity?.currency
    )[0];

    if (fromAccount && opportunity) {
      if (balance) {
        setBalance(balance);
      } else {
        const message =
          "no match from currency opportunity and currency account";
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    }
  }, [fromAccount, opportunity]);

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        if (fromAccountStatus !== "" && fromAccountType !== undefined) {
          const { accounts } = await fetchUserAccount({
            type: fromAccountType,
            status: fromAccountStatus,
            ...(searchFrom && { keyword: searchFrom }),
            size: TABLE_PAGE_SIZE,
          });
          setFromAccounts(accounts);
        }
      } catch (err: any) {
        handleFailure(err);
      }
    };

    void getAllUsers();
  }, [searchFrom, fromAccountType, fromAccountStatus, fromAccount]);

  useEffect(() => {
    const getAllOpportunity = async () => {
      try {
        const opportunities = await fetchOpportunities({
          page: 0,
          size: 50,
          status: "Active",
        });
        setOpportunities(opportunities.opportunityList);
      } catch (err: any) {
        handleFailure(err);
      }
    };

    void getAllOpportunity();
  }, [searchFrom, fromAccountType, fromAccountStatus]);

  useEffect(() => {
    if (fromAccount) {
      setAccountSelected(true);
    }
  }, [fromAccount]);

  const onFormSubmit = async (values: FormValues) => {
    try {
      await createInvestemnt({
        currency: opportunity?.currency || "EUR",
        opportunityId: opportunity?.id || 0,
        investmentType: values.investmentType,
        noToken: opportunity?.noToken,
        tokenPrice: opportunity?.tokenPrice,
        investmentOption: {},
        agreedTandC: values.agreedTandC === "true" ? true : false,
        streamableType: values.streamableType === "true" ? true : false,
        walletId: balance?.wallet_id || "",
        partyId: fromAccount?.account_id,
        amountInvestedForStreamable: values.amountInvestedForStreamable,
      });
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("payment__success__message"),
          type: "success",
        })
      );
      history.push("/payments/outgoing");
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  return (
    <MainTemplate>
      <QuidTitle>{t("create__investment__page__title")}</QuidTitle>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormProvider {...methods}>
            <div className={classes.containerInner}>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__head__from__label")}
                </QuidTitle>
                {!accountSelected === true && (
                  <>
                    <TextField
                      label={t("filter__search")}
                      name="from_search"
                      variant="outlined"
                      fullWidth
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setSearchFrom(e.target.value)
                      }
                      value={searchFrom}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div className={classes.searchBox}>
                              <SearchIcon color="#fff" />
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {fromAccounts?.map(
                      (account: AccountResponse, index: number) => (
                        <div
                          key={index}
                          onClick={() => {
                            setFromAccount(account);
                          }}
                          className={classes.accountDetailsWrapper}
                        >
                          <div>
                            <Typography variant="body1">
                              {account.account_name} - {account.account_id}
                              <br />
                              <small>{account.account_holder}</small>
                            </Typography>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
                {fromAccount && (
                  <DetailsBlock label="" toPrint={fromAccountDetails} />
                )}
                {balance && (
                  <div>
                    <DetailsBlock label="" toPrint={transactionDetails} />
                  </div>
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__opportunity__label")}
                </QuidTitle>
                {opportunity === undefined && (
                  <>
                    {opportunities?.map(
                      (opportunity: Opportunity, index: number) => (
                        <div
                          key={index}
                          onClick={() => {
                            setOpportunity({
                              ...opportunity,
                              keyFacts: undefined,
                              details: undefined,
                              datafiles: undefined,
                              opportunitySetup: undefined,
                              beneficiaryWallets: undefined,
                              smartContract: undefined,
                            });
                          }}
                          className={classes.accountDetailsWrapper}
                        >
                          <div>
                            <Typography variant="body1">
                              {opportunity.id} - {opportunity.name}
                              <br />
                            </Typography>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
                {opportunity && (
                  <DetailsBlock
                    label="Opportunity Details"
                    toPrint={opportunityDetails}
                  />
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__info")}
                </QuidTitle>
                {fromAccount && (
                  <div>
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      name="amount"
                      rules={{
                        required: t(
                          "internal__transfer__form__amount__required"
                        ) as string,
                      }}
                      label={t("internal__transfer__form__amount__label")}
                      defaultValues={defaultValues}
                    />
                    <QuidTextField
                      rules={{
                        required: t("investmentType__required") as string,
                      }}
                      name="investmentType"
                      defaultValues={defaultValues}
                      label={t("investment__type__placeholder")}
                      textFieldProps={{ select: true, fullWidth: true }}
                    >
                      {investmentTypeOptions.map(
                        (type: string, index: number) => (
                          <MenuItem key={`${type}-${index}`} value={type}>
                            {type}
                          </MenuItem>
                        )
                      )}
                    </QuidTextField>

                    <QuidTextField
                      rules={{
                        required: t("agreedTanc__required") as string,
                      }}
                      name="agreedTandC"
                      defaultValues={defaultValues}
                      label={t("investment__agreedTandC__placeholder")}
                      textFieldProps={{ select: true, fullWidth: true }}
                    >
                      {agreedTandCOptions.map(
                        (
                          option: { value: string; label: string },
                          index: number
                        ) => (
                          <MenuItem key={`${index}`} value={option.label}>
                            {option.label}
                          </MenuItem>
                        )
                      )}
                    </QuidTextField>

                    <QuidTextField
                      rules={{
                        required: t("streamableType__required") as string,
                      }}
                      name="streamableType"
                      defaultValues={defaultValues}
                      label={t("investment__streamableType__placeholder")}
                      textFieldProps={{ select: true, fullWidth: true }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setStreamableType(e.target.value)
                      }
                    >
                      {agreedTandCOptions.map(
                        (
                          option: { value: string; label: string },
                          index: number
                        ) => (
                          <MenuItem key={`${index}`} value={option.label}>
                            {option.label}
                          </MenuItem>
                        )
                      )}
                    </QuidTextField>

                    {streamableType === "true" ? (
                      <QuidTextField
                        textFieldProps={{ fullWidth: true }}
                        name="amountInvestedForStreamable"
                        label={t(
                          "investment__amountInvestedForStreamable__label"
                        )}
                        defaultValues={defaultValues}
                        rules={undefined}
                      />
                    ) : null}

                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      name="investmentOption"
                      label={t("investment__investmentOption__label")}
                      defaultValues={defaultValues}
                      rules={undefined}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.containerInnerButtons}>
              <Button variant="contained" color="primary" type="submit">
                {t("internal__transfer__create__button")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={onResetForm}
              >
                {t("internal__transfer__reset__button")}
              </Button>
            </div>
          </FormProvider>
        </form>
      </div>
    </MainTemplate>
  );
};

export default PaymentsInternalTransfer;
