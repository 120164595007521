import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { IconButton, Typography } from "@material-ui/core";
import IconUser from "./icons/IconUser";
import Quid from "./icons/Quid";
import Toonie from "./icons/Toonie";
import Elviria from "./icons/Elviria";
import Dashboard from "./icons/Dashboard";
import Accounts from "./icons/Accounts";
import Conversions from "./icons/Conversions";
import Offers from "./icons/Offers";
import DashboardIcon from "./icons/DashboardIcon";
import Vaffa from "./icons/Vaffa";
import Strfi from "./icons/Strfi";
import {
  ShoppingCart,
  Business,
  Person,
  Group,
  AccountBalanceWallet,
  PaymentSharp,
  Landscape,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: "#FFF",
    width: "calc(100% / 12 * 1)",
    minWidth: "120px",
    boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.1)",
    overflowY: "auto",
    position: "fixed",
    zIndex: 10,
    top: 0,
    left: 0,
    "&:hover": {
      width: "calc((100% / 12 * 1) + 300px))",
    },
  },
  sidebarLogo: {
    width: "30%",
    textAlign: "center",
    margin: "15px auto 30px",
    "& svg": {
      maxWidth: "40px",
      marign: "0px auto 0px auto",
    },
  },
  sidebarUser: {
    width: "50%",
    textAlign: "center",
    margin: "30px auto 15px",
  },
  sidebarUserButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px auto",
  },
  listGroup: {
    border: 0,
  },
  listGroupItem: {
    color: "#91A1AD",
    lineHeight: "20px",
    textAlign: "center",
    border: 0,
    textDecoration: "none",
    marginBottom: "25px",
    display: "block",
    position: "relative",
    "&:hover": {
      color: theme.palette.primary.main,
      "& $listGroupItemSubmenu": {
        display: "block",
        width: "300px",
        position: "fixed",
        background: "#FAFAFA",
        left: "0px",
        marginTop: "-55px",
        marginLeft: "max(calc(100% / 12 * 1), 120px)",
        padding: "30px 30px 0px 30px",
        boxShadow:
          "inset 4px 0px 11px -4px rgba(0,0,0,0.1), 3px 2px 5px 0 rgba(0,0,0,0.1)",
        zIndex: "-1",
        "& $listGroupItem": {
          textAlign: "left",
        },
      },
    },
    "&:focus": {
      background: "none",
    },
    "&:active": {
      background: "none",
    },
    "& svg": {
      maxWidth: "20px",
      marign: "0px auto 5px auto",
    },
  },
  listGroupItemSubmenu: {
    display: "none",
  },
  listGroupItemTitle: {
    marginTop: "0px",
    marginBottom: "0px",
  },
}));

interface SidebarLink {
  link: string;
  icon?: any;
  title: string;
  items?: SidebarLink[];
}

type SidebarComponentProps = {
  onLogout: () => void;
};

const SidebarComponent: React.FC<SidebarComponentProps> = ({ onLogout }) => {
  const classes = useStyles();
  const { t } = useTranslation("sidebar");
  const [dataLinks, setDataLinks] = useState([] as SidebarLink[]);

  const IconSidebarValue =
    process.env.REACT_APP_PROJECT === "quid" ? (
      <Quid />
    ) : process.env.REACT_APP_PROJECT === "toonie" ? (
      <Toonie />
    ) : process.env.REACT_APP_PROJECT === "elviria" ? (
      <Elviria />
    ) : process.env.REACT_APP_PROJECT === "konfido" ? (
      <></>
    ) : process.env.REACT_APP_PROJECT === "strfiinvest" ? (
      <Strfi />
    ) : (
      <Vaffa />
    );

  useEffect(() => {
    setDataLinks([
      {
        link: "/",
        icon: <DashboardIcon />,
        title: "dashboard",
      },
    ]);
    if (process.env.REACT_APP_PROJECT === "quid") {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "/task",
          icon: <Dashboard />,
          title: "sidebar__tasks",
          items: [
            {
              link: "/task",
              title: "sidebar__tasks__kyc",
            },
            {
              link: "/tasksaccounts",
              title: "sidebar__tasks__accounts",
            },
            {
              link: "/taskscompanyformations",
              title: "sidebar__tasks__companyformations",
            },
          ],
        },
        {
          link: "/history/task",
          icon: <Dashboard />,
          title: "sidebar__history",
          items: [
            {
              link: "/history/task",
              title: "sidebar__tasks__history__kyc",
            },
            {
              link: "/history/accounts",
              title: "sidebar__tasks__history__accounts",
            },
            {
              link: "/history/companyformations",
              title: "sidebar__tasks__history__companyformations",
            },
          ],
        },
      ]);
    }
    if (process.env.REACT_APP_PROJECT === "toonie") {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "/task",
          icon: <Dashboard />,
          title: "sidebar__tasks",
        },
        {
          link: "/history/task",
          icon: <Dashboard />,
          title: "sidebar__history",
          items: [
            {
              link: "/history/task",
              title: "sidebar__tasks__history__kyc",
            },
          ],
        },
      ]);
    }
    if (
      process.env.REACT_APP_PROJECT === "elviria" ||
      process.env.REACT_APP_PROJECT === "konfido" ||
      process.env.REACT_APP_PROJECT === "strfiinvest"
    ) {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "/task",
          icon: <Dashboard />,
          title: "sidebar__tasks",
          items: [
            {
              link: "/task",
              title: "sidebar__tasks__kyc",
            },
            {
              link: "/tasksaccounts",
              title: "sidebar__tasks__accounts",
            },
          ],
        },
        {
          link: "/history/task",
          icon: <Dashboard />,
          title: "sidebar__history",
          items: [
            {
              link: "/history/task",
              title: "sidebar__tasks__history__kyc",
            },
            {
              link: "/history/accounts",
              title: "sidebar__tasks__history__accounts",
            },
          ],
        },
      ]);
    }
    if (process.env.REACT_APP_PROJECT === "toonie") {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "/reconciliation",
          icon: <Offers />,
          title: "sidebar__reconciliation",
        },
      ]);
    }
    if (
      process.env.REACT_APP_PROJECT === "vaffa" ||
      process.env.REACT_APP_PROJECT === "ats"
    ) {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "/task",
          icon: <Dashboard />,
          title: "sidebar__tasks",
          items: [
            {
              link: "/task",
              title: "sidebar__tasks__kyc",
            },
          ],
        },
        {
          link: "/history/task",
          icon: <Dashboard />,
          title: "sidebar__history",
          items: [
            {
              link: "/history/task",
              title: "sidebar__tasks__history__kyc",
            },
          ],
        },
      ]);
    }
    setDataLinks((dataLinks) => [
      ...dataLinks,
      {
        link: "/users",
        icon: <Group />,
        title: "sidebar__users",
      },
      {
        link: "/customers",
        icon: <Person />,
        title: "sidebar__customers",
      },
      {
        link: "/companies",
        icon: <Business />,
        title: "sidebar__companies",
      },
    ]);
    if (
      process.env.REACT_APP_PROJECT === "quid" ||
      process.env.REACT_APP_PROJECT === "toonie" ||
      process.env.REACT_APP_PROJECT === "elviria" ||
      process.env.REACT_APP_PROJECT === "konfido" ||
      process.env.REACT_APP_PROJECT === "strfiinvest"
    ) {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "",
          icon: <Accounts />,
          title: "sidebar__accounts",
          items: [
            {
              link: "/accounts/customers",
              title: "sidebar__accounts__customers",
            },
            {
              link: "/accounts/revenues",
              title: "sidebar__accounts__revenues",
            },
            {
              link: "/accounts/balances",
              title: "sidebar__accounts__balances",
            },
          ],
        },
      ]);
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "",
          icon: <PaymentSharp />,
          title: "sidebar__payments",
          items: [
            {
              link: "/payments/transactions",
              title: "sidebar__payments__transactions",
            },
            process.env.REACT_APP_ENABLE_TRANSACTIONS_BEHALF === "true"
              ? {
                  link: "/payments/transactions/behalf",
                  title: "sidebar__payments__transactions__behalf",
                }
              : { link: "", title: "" },
            {
              link: "/pay/internal",
              title: "sidebar__payments__new_internal",
            },
            {
              link: "/pay/balance-adjustments",
              title: "sidebar__payments__balance_adjustments",
            },
            {
              link: "/pay/conversion",
              title: "sidebar__payments__internal__conversion",
            },
            {
              link: "/payments/outgoing",
              title: "sidebar__payments__outgoing",
            },
            {
              link: "/payments/incoming",
              title: "sidebar__payments__incoming",
            },
            {
              link: "/payments/bulk",
              title: "sidebar__payments__bulk_internal",
            },
            {
              link: "/payments/acquiring",
              title: "sidebar__payments__acquiring",
            },
          ],
        },
      ]);
    }
    if (process.env.REACT_APP_PROJECT === "vaffa") {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "",
          icon: <ShoppingCart />,
          title: "sidebar__commerce",
          items: [
            {
              link: "/commerce/cart",
              title: "sidebar__cart",
            },
            {
              link: "/commerce/payments",
              title: "sidebar__commerce_payments",
            },
            {
              link: "/commerce/products",
              title: "sidebar__product",
            },
          ],
        },
      ]);
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "",
          icon: <Landscape />,
          title: "sidebar__land",
          items: [
            {
              link: "/land/payments",
              title: "sidebar__land_payments",
            },
            {
              link: "/land/buyers",
              title: "sidebar__land_buyers",
            },
            {
              link: "/land/prepurchase",
              title: "sidebar__land_prepurchases",
            },
          ],
        },
      ]);
    }
    if (process.env.REACT_APP_PROJECT === "quid") {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "",
          icon: <Conversions />,
          title: "sidebar__conversions",
          items: [
            {
              link: "/conversions/list",
              title: "sidebar__conversions",
            },
          ],
        },
        {
          icon: <Offers />,
          link: "/rewards",
          title: "sidebar__rewards",
        },
      ]);
    }
    if (process.env.REACT_APP_PROJECT === "toonie") {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "/offers",
          icon: <Offers />,
          title: "sidebar__offers",
          items: [
            { link: "/offers", title: "sidebar__offers" },
            {
              link: "/rewards",
              title: "sidebar__rewards",
            },
          ],
        },
        {
          link: "",
          icon: <Conversions />,
          title: "sidebar__conversions",
          items: [
            {
              link: "/conversions/list",
              title: "sidebar__conversions",
            },
            {
              link: "/conversions/change/fx",
              title: "sidebar__conversions_change_fx",
            },
          ],
        },
        {
          link: "",
          icon: <Offers />,
          title: "sidebar__gift_cards",
          items: [
            {
              link: "/cards/gift-cards",
              title: "sidebar__gift_cards",
            },
            {
              link: "/cards/gift-cards/programs",
              title: "sidebar__cards_programs",
            },
          ],
        },
        {
          link: "",
          icon: <Offers />,
          title: "sidebar__cards",
          items: [
            {
              link: "/cards/providers",
              title: "sidebar__card_providers",
            },
            {
              link: "/cards/card-list",
              title: "sidebar__card_list",
            },
            {
              link: "/cards/card-programs",
              title: "sidebar__card_programs",
            },
          ],
        },
      ]);
    }
    if (
      process.env.REACT_APP_PROJECT === "elviria" ||
      process.env.REACT_APP_PROJECT === "konfido" ||
      process.env.REACT_APP_PROJECT === "strfiinvest"
    ) {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "/opportunities",
          icon: <Offers />,
          title: "sidebar__opportunities",
        },
        {
          link: "",
          icon: <Offers />,
          title: "sidebar__investments",
          items: [
            {
              link: "/investments",
              title: "sidebar__investments",
            },
            {
              link: "/investments-create",
              title: "sidebar__investments-create",
            },
          ],
        },
        {
          link: "/news",
          icon: <Offers />,
          title: "sidebar__news",
        },
      ]);
    }
    if (
      process.env.REACT_APP_PROJECT === "vaffa" ||
      process.env.REACT_APP_PROJECT === "ats" ||
      process.env.REACT_APP_PROJECT === "strfiinvest"
    ) {
      setDataLinks((dataLinks) => [
        ...dataLinks,
        {
          link: "/crypto/wallets",
          icon: <AccountBalanceWallet />,
          title: "sidebar__crypto_wallets",
        },
        {
          link: "",
          icon: <PaymentSharp />,
          title: "sidebar__crypto_payments",
          items: [
            {
              link: "/crypto/payments",
              title: "sidebar__crypto_payments",
            },
            {
              link: "/crypto/payment/create",
              title: "sidebar__crypto__create_payments",
            },
            {
              link: "/crypto/payment/transfer",
              title: "sidebar__crypto__transfer_payments",
            },
          ],
        },
      ]);
    }
  }, []);
  return (
    <div className={classes.sidebar}>
      <div className={classes.sidebarLogo}>
        <Link to="/">{IconSidebarValue}</Link>
      </div>
      {dataLinks.map((link, key) => (
        <Link key={key} to={link.link || ""} className={classes.listGroupItem}>
          {link.icon}
          <Typography variant="body2" className={classes.listGroupItemTitle}>
            {t(link.title)}
          </Typography>
          {link?.items && (
            <div className={classes.listGroupItemSubmenu}>
              {link.items.map((linkSub, key) => (
                <Link
                  key={key}
                  to={linkSub.link}
                  className={classes.listGroupItem}
                >
                  <Typography
                    variant="body2"
                    className={classes.listGroupItemTitle}
                  >
                    {t(linkSub.title)}
                  </Typography>
                </Link>
              ))}
            </div>
          )}
        </Link>
      ))}
      <div className={classes.sidebarUser}>
        <IconButton className={classes.sidebarUserButton} onClick={onLogout}>
          <IconUser />
        </IconButton>
      </div>
    </div>
  );
};

export default SidebarComponent;
