import {
  InvestementBOListResponse,
  InvestementCountDetails,
  InvestementInfoResponse,
  InvestementReferenceDataResponse,
  InvestmentInfo,
  InvestmentPreferences,
  InvestmentRefundResponse,
  InvestmentStopResponse,
  InvestmentsListParams,
  ReportsListParams,
  ReporttBOListResponse,
  Report,
  Investment,
} from "entities/clients/Investements";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";
import { InvestmentDetailResponse } from "@portit/core/entities/Investments";

export async function getInvestementPreferencesByPartyId(
  partyId: number
): Promise<InvestmentPreferences> {
  try {
    const res = await apiClient.get<InvestmentPreferences>(
      `/investments/v1/preferences/parties/${partyId}`,
      "company"
    );
    return res;
  } catch (err: any) {
    return {} as InvestmentPreferences;
  }
}

export async function getInvestementInfoByPartyId(
  partyId: number
): Promise<InvestmentInfo> {
  try {
    const res = await apiClient.get<InvestementInfoResponse>(
      `/investments/v1/infos/${partyId}`,
      "company"
    );
    return res.investmentInfo;
  } catch (err: any) {
    return {} as InvestmentInfo;
  }
}

export async function getInvestementManagemntStats(): Promise<InvestementCountDetails> {
  try {
    const res = await apiClient.get<InvestementCountDetails>(
      `/investments/v1/management/stats`,
      "company"
    );
    return res;
  } catch (err: any) {
    return {} as InvestementCountDetails;
  }
}

export async function acceptInvestement(investmentId: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/management/${investmentId}/accept`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function rejectInvestement(investmentId: number): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/investments/v1/management/${investmentId}/reject`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getInvestementReferenceData(): Promise<InvestementReferenceDataResponse> {
  try {
    const res = await apiClient.get<InvestementReferenceDataResponse>(
      `/investments/v1/referenceData`,
      "company"
    );
    return res;
  } catch (err: any) {
    return {} as InvestementReferenceDataResponse;
  }
}

export async function getInvestementsBO(
  params: InvestmentsListParams
): Promise<InvestementBOListResponse> {
  try {
    const res = await apiClient.get<InvestementBOListResponse>(
      `/investments/v1/investments/bo`,
      "company",
      {
        page: params.page,
        size: params.size,
        order: "id",
        orderType: "desc",
        min: params.min,
        max: params.max,
        fromDate: params.fromDate,
        toDate: params.toDate,
        status: params.status,
        partyId: params.partyId,
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function stopInvestment(
  investmentId: number
): Promise<InvestmentStopResponse> {
  try {
    const res = await apiClient.post<InvestmentStopResponse>(
      `/investments/v1/management/locked/${investmentId}/stop`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getInvestmentDetails(
  investmentId: number
): Promise<InvestmentDetailResponse> {
  try {
    const res = await apiClient.get<InvestmentDetailResponse>(
      `/investments/v1/management/${investmentId}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getInvestmentRoiDetails(
  investmentId: number,
  roiId: number
): Promise<Report> {
  try {
    const res = await apiClient.get<Report>(
      `/investments/v1/management/${investmentId}/roi/${roiId}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getRoiDetails(roiId: number): Promise<Report> {
  try {
    const res = await apiClient.get<Report>(
      `/investments/v1/management/roi/${roiId}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createInvestmentRoi(params: Report): Promise<Report> {
  try {
    const res = await apiClient.post<Report>(
      `/investments/v1/management/roi`,
      "company",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateInvestmentRoi(
  roiId: number,
  params: Report
): Promise<Report> {
  try {
    const res = await apiClient.put<Report>(
      `/investments/v1/management/roi/${roiId}`,
      "company",
      {
        investmentId: params.investmentId as number,
        investDate: params.investDate,
        reportDate: params.reportDate,
        investAmount: params.investAmount as number,
        roiPercent: params.roiPercent as number,
        realized: params.realized as boolean,
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getInvestementsReportsBO(
  params: ReportsListParams
): Promise<ReporttBOListResponse> {
  try {
    const res = await apiClient.get<ReporttBOListResponse>(
      `/investments/v1/management/roi/investment/${params.investmentId}`,
      "company",
      {
        page: params.page,
        size: params.size,
        order: "id",
        orderType: "desc",
        min: params.min,
        max: params.max,
        fromDate: params.fromDate,
        toDate: params.toDate,
        status: params.status,
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function refundInvestment(
  investmentId: number
): Promise<InvestmentRefundResponse> {
  try {
    const res = await apiClient.post<InvestmentRefundResponse>(
      `/investments/v1/management/locked/${investmentId}/refund`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createInvestemnt(
  params: Investment
): Promise<Investment> {
  try {
    const res = await apiClient.post<Investment>(
      `/investments/v1/management/invest`,
      "company",
      {
        opportunityId: params.opportunityId,
        investmentType: params.investmentType,
        noToken: params.noToken,
        tokenPrice: params.tokenPrice,
        amountInvestedForStreamable: params.amountInvestedForStreamable,
        currency: params.currency,
        agreedTandC: params.agreedTandC,
        investmentOption: params.investmentOption,
        streamableType: params.streamableType,
        walletId: params.walletId,
        partyId: params.partyId,
        referralCode: params.referralCode,
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
