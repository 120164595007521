import { Typography, Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import { handleFailure } from "resHandlers";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import clsx from "clsx";
import {
  fetchCardProgramsDetailsById,
  fetchCardProgramsCardsById,
} from "api/cards";
import {
  CardProgramCards,
  CardProgramResponse,
} from "entities/cards/CardEntity";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import { formatDate } from "utils";
import { Pagination } from "entities/accounts/Pagination";
import { TABLE_PAGE_SIZE } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  detailContainer: {
    background: "#FAFAFA",
    width: "100%",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  pl24: {
    paddingLeft: 24,
  },
  pr24: {
    paddingRight: 24,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
}));

const CardProgramsDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("cards");

  const classes = useStyles();

  const id = parseInt(match.params.id, 10);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([] as CardProgramCards[]);
  const [pagination, setPagination] = useState({} as Pagination);
  const [details, setDetails] = useState({} as CardProgramResponse);

  const margins = clsx(classes.pl24, classes.pr24, classes.mt32, classes.mb32);

  const cardProgramsDetailsToPrint: CardProgramResponse = {
    id: details?.id || "n/a",
    currency: details?.currency || "n/a",
    name: details?.name || "n/a",
    provider_type: details?.provider_type || "n/a",
    status: details?.status || "n/a",
    type: details?.type || "n/a",
    description: details?.description || "n/a",
    configuration: JSON.stringify(details?.configuration) || "n/a",
  };

  // status: string;

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      field: "party_id",
      headerName: t("listTable__header_name__party_id"),
    },
    {
      field: "card_provider_id",
      headerName: t("listTable__header_name__card_provider_id"),
    },
    {
      field: "number",
      flex: 1,
      headerName: t("listTable__header_name__number"),
    },
    {
      field: "holder_name",
      flex: 1,
      headerName: t("listTable__header_name__holder_name"),
    },
    {
      field: "provider",
      flex: 1,
      headerName: t("listTable__header_name__provider"),
    },
    {
      field: "expiry",
      flex: 1,
      headerName: t("listTable__header_name__expiring_date"),
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params?.row?.expiry, "dd/MM/yyyy")}
          <small>{formatDate(params?.row?.expiry, "HH:mm:ss")}</small>
        </div>
      ),
    },
    {
      field: "balance",
      flex: 1,
      headerName: t("listTable__header_name__balance"),
      renderCell: (params) => (
        <>
          {MoneyFormatter(
            params?.row?.balance,
            params?.row?.currency as DineroFactory.Currency
          ) || "n/a"}
        </>
      ),
    },
    {
      field: "status",
      flex: 1,
      headerName: t("listTable__header_name__status"),
    },
  ];

  const fetchCards = useCallback(async () => {
    try {
      if (id) {
        setLoading(true);
        const res = await fetchCardProgramsCardsById({
          cardId: id,
          page,
          size: TABLE_PAGE_SIZE,
        });

        setPagination(res?.pagination);
        setCards(res?.cards);
      }
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setLoading(false);
    }
  }, [id, page]);

  const fetchCardProgramsDetails = useCallback(async () => {
    try {
      if (id) {
        const res = await fetchCardProgramsDetailsById({ cardId: id });

        setDetails(res);
      }
    } catch (err: any) {
      handleFailure(err);
    }
  }, [id]);

  useEffect(() => {
    fetchCardProgramsDetails();
    fetchCards();
  }, []);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  return (
    <MainTemplate>
      <div className={classes.cardsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("card_programs_details__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.detailContainer}>
          <Grid container className={margins} justify={"center"}>
            <Grid item xs={6}>
              <DetailsBlock
                toPrint={cardProgramsDetailsToPrint}
                label={t("card_programs__details__title")}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.title}>
              {t("card_programs__details__cards")}
            </Typography>
          </Grid>
        </Grid>
        <QuidDataGrid
          disableSelectionOnClick
          loading={loading}
          columns={columns}
          rows={cards}
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          rowCount={pagination?.total_entries}
          pageSize={10}
        />
      </div>
    </MainTemplate>
  );
};

export default CardProgramsDetails;
