import { Typography, Grid, makeStyles, Button } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import { handleFailure } from "resHandlers";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import clsx from "clsx";
import {
  activeCardById,
  cancelCardById,
  fetchCardById,
  fetchCardFullTransactionsById,
  stolenCardById,
  suspendCardById,
} from "api/cards";
import { Card, FullTransactions } from "entities/cards/CardEntity";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { ColDef } from "@material-ui/data-grid";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import { formatDate } from "utils";
import LoadUnloadModal from "components/organisms/cards/LoadUnloadModal";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { downloaCardTransactionsCsv } from "services/cards";
import Loader from "components/atoms/icons/Loader";
import CsvIcon from "components/atoms/icons/CsvIcon";

const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  actionButtons: {
    marginTop: 16,
    marginBottom: 16,
    display: "flex",
    gap: 8,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    display: "flex",
    gap: 8,
    marginBottom: 32,
  },
  detailContainer: {
    background: "#FAFAFA",
    width: "100%",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  pl24: {
    paddingLeft: 24,
  },
  pr24: {
    paddingRight: 24,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
  redButton: {
    backgroundColor: "#FF6F0F",
  },
  greenButton: {
    backgroundColor: "#A1F714",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100vh",
    zIndex: 15,
  },
  buttonCsvContainer: {
    marginLeft: "auto",
    width: 80,
  },
}));

const CardListDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("cards");

  const classes = useStyles();
  const id = parseInt(match.params.id, 10);

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({} as Card);
  const [modal, setmodal] = useState({ isOpen: false, type: "" });
  const [fullTransactions, setFullTransactions] = useState(
    [] as FullTransactions[]
  );

  const margins = clsx(classes.pl24, classes.pr24, classes.mt32, classes.mb32);

  const cardDetailsToPrint: Card = {
    id: details?.id || "n/a",
    status: details?.status || "n/a",
    balance:
      MoneyFormatter(
        +details?.balance,
        details?.currency as DineroFactory.Currency
      ) || "n/a",
    number: details?.number || "n/a",
    currency: details?.currency || "n/a",
    expiry: formatDate(details?.expiry, "dd/MM/yyyy") || "n/a",
    holder_name: details?.holder_name || "n/a",
    card_provider_id: details?.card_provider_id || "n/a",
    provider: details?.provider || "n/a",
    party_id: details?.party_id || "n/a",
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      field: "merchant_name",
      flex: 1,
      headerName: t("listTable__header_name__merchant_name"),
      renderCell: (params) => <>{params?.row?.merchant_name || "n/a"}</>,
    },
    {
      field: "date",
      flex: 1,
      headerName: t("listTable__header_name__date"),
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params?.row?.date, "dd/MM/yyyy")}
          <small>{formatDate(params?.row?.date, "HH:mm:ss")}</small>
        </div>
      ),
    },
    {
      field: "card_currency",
      flex: 1,
      headerName: t("listTable__header_name__card_currency"),
    },
    {
      field: "balance",
      flex: 1,
      headerName: t("listTable__header_name__balance"),
      renderCell: (params) => (
        <>
          {MoneyFormatter(
            params?.row?.balance,
            params?.row?.card_currency as DineroFactory.Currency
          ) || "n/a"}
        </>
      ),
    },
    {
      field: "transaction_currency",
      flex: 1,
      headerName: t("listTable__header_name__transaction_currency"),
    },
    {
      field: "amount_in_transaction_currency",
      flex: 1,
      headerName: t("listTable__header_name__amount_in_transaction_currency"),
      renderCell: (params) => (
        <>
          {MoneyFormatter(
            params?.row?.amount_in_transaction_currency,
            params?.row?.transaction_currency as DineroFactory.Currency
          ) || "n/a"}
        </>
      ),
    },
    {
      field: "transaction_reference",
      flex: 1,
      headerName: t("listTable__header_name__transaction_reference"),
    },
    {
      field: "transaction_type",
      flex: 1,
      headerName: t("listTable__header_name__transaction_type"),
    },
  ];

  const fetchCardFullTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchCardFullTransactionsById({ id });
      const sanitizeResponse = res?.transactions?.map((props, index) => ({
        id: index + 1,
        ...props,
      }));
      setFullTransactions(sanitizeResponse);
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchCardDetails = useCallback(async () => {
    try {
      const res = await fetchCardById({ id });
      setDetails(res);
    } catch (err: any) {
      handleFailure(err);
    }
  }, [id]);

  useEffect(() => {
    fetchCardDetails();
    fetchCardFullTransactions();
  }, []);

  const stolenCard = async () => {
    try {
      await stolenCardById({ id });
      await fetchCardDetails();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const cancelCard = async () => {
    try {
      await cancelCardById({ id });
      await fetchCardDetails();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const suspendCard = async () => {
    try {
      await suspendCardById({ id });
      await fetchCardDetails();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const activeCard = async () => {
    try {
      await activeCardById({ id });
      await fetchCardDetails();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () => downloaCardTransactionsCsv({ cardId: id }),
  });

  return (
    <>
      {documentLoading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <LoadUnloadModal
        id={id}
        type={modal.type}
        open={modal.isOpen}
        refetch={() => null}
        onCloseModal={() => setmodal({ isOpen: false, type: "" })}
      />
      <MainTemplate>
        <div className={classes.accountsContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("card__details__page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <Grid container>
            <div className={classes.actionButtons}>
              <div className={classes.buttons}>
                <Button
                  onClick={activeCard}
                  variant="contained"
                  className={classes.greenButton}
                  disabled={
                    details?.status?.toLowerCase() === "active" ||
                    details?.status?.toLowerCase() === "stolen"
                  }
                >
                  {t("button__active")}
                </Button>
                <Button
                  onClick={cancelCard}
                  variant="contained"
                  className={classes.redButton}
                  disabled={
                    details?.status?.toLowerCase() === "cancelled" ||
                    details?.status?.toLowerCase() === "stolen"
                  }
                >
                  {t("button__cancel")}
                </Button>
                <Button
                  onClick={suspendCard}
                  variant="contained"
                  className={classes.redButton}
                  disabled={
                    details?.status?.toLowerCase() === "suspended" ||
                    details?.status?.toLowerCase() === "stolen"
                  }
                >
                  {t("button__suspend")}
                </Button>
                <Button
                  onClick={stolenCard}
                  variant="contained"
                  className={classes.redButton}
                  disabled={details?.status?.toLowerCase() === "stolen"}
                >
                  {t("button__stolen")}
                </Button>
              </div>
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  className={classes.greenButton}
                  disabled={details?.status?.toLowerCase() === "stolen"}
                  onClick={() => setmodal({ isOpen: true, type: "load" })}
                >
                  {t("button__load")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.redButton}
                  disabled={details?.status?.toLowerCase() === "stolen"}
                  onClick={() => setmodal({ isOpen: true, type: "unload" })}
                >
                  {t("button__unload")}
                </Button>
              </div>
            </div>
          </Grid>
          <div className={classes.detailContainer}>
            <Grid container className={margins} justify={"center"}>
              <Grid item xs={6}>
                <DetailsBlock
                  toPrint={cardDetailsToPrint}
                  label={t("card__details__title")}
                />
              </Grid>
            </Grid>
          </div>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>
                {t("card__details__full_transactions")}
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.buttonCsvContainer}>
            <Button
              variant="outlined"
              onClick={() => onCsvDownload()}
              className={classes.csvBtn}
            >
              <CsvIcon />
            </Button>
          </div>
          <QuidDataGrid
            loading={loading}
            columns={columns}
            rows={fullTransactions}
            pageSize={fullTransactions?.length}
            rowCount={fullTransactions?.length}
            sortModel={[{ field: "id", sort: "desc" }]}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default CardListDetails;
